<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Veranstaltungskategorien">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="loadEventCategories"
        ></region-chooser>
        <el-button type="primary" icon="fal fa-plus" @click="newEventCategory">
          Neue Kategorie
        </el-button>
      </template>
      <el-tabs type="border-card">
        <el-tab-pane label="Kategorien anzeigen">
          <el-table v-loading="loading" :data="eventCategories" stripe>
            <el-table-column prop="name" label="Name"></el-table-column>
            <el-table-column label="Icon">
              <template #default="scope">
                <div
                  :style="{
                    'background-color': scope.row.background_color,
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    padding: '3px'
                  }"
                >
                  <img :src="scope.row.icon_url" />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Aktionen" width="260">
              <template #default="scope">
                <el-button
                  icon="fal fa-edit"
                  size="mini"
                  @click="handleEdit(scope.row)"
                >
                  Bearbeiten
                </el-button>
                <delete-button
                  v-slot="slotProps"
                  :on-delete="deleteCategory"
                  subject="Kategorie"
                >
                  <el-button
                    size="mini"
                    icon="fal fa-trash-alt"
                    type="danger"
                    @click="slotProps.handleDelete(scope.row)"
                  >
                    Löschen
                  </el-button>
                </delete-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Kategorien sortieren">
          <el-alert
            title="Hinweis"
            description="Die Kategorien können per Drag & Drop sortiert werden."
            type="info"
            show-icon
            style="margin-bottom: 20px;"
          >
          </el-alert>
          <el-tree
            :props="treeProps"
            :data="eventCategories"
            node-key="id"
            draggable
            :allow-drop="allowDrop"
            @node-drop="handleDrop"
          >
          </el-tree>
        </el-tab-pane>
      </el-tabs>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"
import DeleteButton from "@/components/DeleteButton.js"
import EventCategoriesRepository from "@/repositories/event_categories_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Veranstaltungskategorien"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser,
    DeleteButton
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: false,
      eventCategories: [],
      treeProps: {
        label: "name"
      }
    }
  },
  async created() {
    this.loadEventCategories()
  },
  methods: {
    async loadEventCategories() {
      this.loading = true

      try {
        this.eventCategories = await EventCategoriesRepository.getAll()
        this.loading = false
      } catch (error) {
        this.loading = false
        this.handleApiError(error)
      }
    },
    handleEdit(eventCategory) {
      this.$router.push({
        name: "EditEventCategoryPage",
        params: { id: eventCategory.id }
      })
    },
    async deleteCategory(eventCategory) {
      this.eventCategories.splice(
        this.eventCategories.indexOf(eventCategory),
        1
      )
      await EventCategoriesRepository.destroy(eventCategory.id)
    },
    newEventCategory() {
      this.$router.push({ name: "NewEventCategoryPage" })
    },
    async handleDrop() {
      try {
        const category_ids = this.eventCategories.map(category => category.id)
        EventCategoriesRepository.sort(category_ids)
      } catch (error) {
        this.handleApiError(error)
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner"
    }
  }
}
</script>
